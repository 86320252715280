'use strict'

import { InputGroup, ProgressBar } from "@blueprintjs/core";
import ServiceButton from "../Group/ServiceButton";

const ServiceSearchResults = ({ searchValue, updateSearchValue, searchResults, getIsManager, isLoading = false }) => {
    

    let serviceButtons = searchResults.map((service, index) => {
        return (<ServiceButton key={index} service={service} showGroup={true} color={"gray"} showTagManager={false} getIsManager={getIsManager} />);
    })

    return (
        <div style={{ margin: 5, padding: 5, border: "1px solid black", backgroundColor: "gray" }}>
            <InputGroup style={{ marginBottom: 5, border: "1px solid black", backgroundColor: "lightgray" }} leftIcon="filter" value={searchValue} onChange={updateSearchValue} placeholder="Search for a service..." />
            <div>
                {(isLoading ? <ProgressBar/> : serviceButtons )}
            </div>
        </div>
    )
}

export default ServiceSearchResults