
'use strict';

import React from "react";

import GroupButton from "./GroupButton";

const OrgContainer = ({ unit, getIsManager }) => {

    const style = {
      container : {
        border : "1px solid black",
        backgroundColor : "gray",
        padding : 5,
        margin : 5,
      },
      title : {
        fontSize : "1.5rem",
        fontWeight: "bold",
        textDecoration: "underline",
        color: "white",
        textShadow : "2px 2px 0px rgba(0, 0, 0, 1)",
        marginBottom: 10,
      },
      group_list : {
        display : "flex",
        gap: "10px",
        width : "100%",
        flexBasis: "100px",
        flexWrap: "wrap",
      }
    };

    let unit_name = unit.name;
    let groups = unit.groups;
    let color = unit.color;
    let groupButtons = [];

    groups.sort(function (a, b) {
      if(a.name > b.name) { return 1; }
      if(a.name < b.name) { return -1; }
      if(a.name == b.name) { return 0; }
    });

    groups.map(function (group) {
      if(group.status > 0) {
        const isManager = getIsManager(group.id);
        if(group.status == 2 && !isManager) { return; }
        groupButtons.push(<GroupButton group={group} color={color} key={group.id}/>);
      }
    });

    return ( <div style={style.container}>
      <div style={style.title}>
        {unit_name}
      </div>
      <div style={style.group_list}>
        {groupButtons}
      </div>
    </div> );
}

export default OrgContainer;
