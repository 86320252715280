
'use strict';

import React from "react";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import AppData from "data-modules/AppData";
import ServiceData from "data-modules/ServiceData";
import SiteDialogData from "data-modules/SiteDialogData";
import moment from "moment";
import MarkdownIt from "markdown-it";
let markdown = new MarkdownIt({
    html: true
});
import { AnchorButton, ButtonGroup, Callout, Button } from "@blueprintjs/core"

class ServiceButton extends React.Component {
  constructor() {
    super();
    this.state = {
      isWritingMessage : false,
      isSending : false,
      isInfoDialogOpen: false,
      startDate : moment().add(-1, "months"),
      endDate : moment(),
      subject : "",
      message : ""
    };
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.valueSet = this.valueSet.bind(this)
    this.clickHandler = this.clickHandler.bind(this);
    this.openTagManager = this.openTagManager.bind(this);
  }
  shouldComponentUpdate(nextProps, _nextState) {
    if(this.props !== nextProps) { return true; }
    return false;
  }
  componentDidMount () {
    DM.add(ServiceData.registerListener("MESSAGE_SENT", this.onMessageSent), ServiceData);
    DM.add(ServiceData.registerListener("MESSAGE_SEND_ERROR", this.onMessageError), ServiceData);
  }
  componentWillUnmount () {
    DM.clear();
  }
  valueSet () { this.setState({}); }
  openTagManager(service) { if(this.props.onOpenTagManager) { this.props.onOpenTagManager(service); } } 
  clickHandler () {
    var id = this.props.service.id;
    switch(this.props.service.type) {
      case 0: {
        window.location.hash = "#/service-calendar/" + id;
      } break;
      case "group-scheduled": {
        window.location.hash = "#/group-calendar/" + id;
      } break;
      case 1 : {
        window.location = this.props.service.link;
      } break;
      case 2: {
        window.location = this.props.service.link;
      } break;
      case 5 : {
        window.location = "#/GSIPT/"+this.props.service.group_id+"/wizard/"+id;
      } break;
      case 6 : {
        window.location = "#/BBSP/"+this.props.service.group_id+"/wizard/"+id;
      } break;
      case 7: {
        window.location = "#/MSSP/" + this.props.service.group_id + "/wizard/" + id;
      } break;
      case 9: {
        window.location = `#/FASTA/${this.props.service.group_id}/wizard/${id}`;
      } break;
      case 10: {
        window.location = `#/walkup/${this.props.service.id}/wizard`;
      } break;
      default : {
        window.location.hash = "#/service/"+id;
      } break;
    }
  }
  render () {
    let self = this;
    let name = self.props.service.name;
    let location = self.props.service.location || "";
    let color = self.props.service.color || "#cecece"
    let id = self.props.service.id;
    let group_id = self.props.service.group_id;
    var serviceNotes = markdown.render(self.props.service.service_notes || "No Information");

    let isManager = (AppData.authorize(20, group_id) && self.props.service.type !== "group-scheduled");
    let buttonStyle = { 
      backgroundColor: color, 
      display: "flex",  
      justifyContent : "space-between", 
      marginBottom: 5, 
      marginRight: 5,
      width: "100%",
      alignItems: "center"
    };
    if(isManager) {
      return (
          <Callout onClick={self.clickHandler} className={"cores-service-manage-button"} style={buttonStyle}> 
            <span style={{ fontWeight: "bold" }}>{(self.props?.showGroup === true ? `${self.props.service.group.name} - ` : "")} {name} {location}</span>
            <ButtonGroup>  
              <Button icon="info-sign" intent="primary" onClick={(e) => { SiteDialogData.open(`${name} Information`, <div dangerouslySetInnerHTML={{ __html: serviceNotes }}/>); e.stopPropagation(); }}/>
              <AnchorButton intent="success" href={"#/manage/group/"+group_id+"/service/"+id} icon="cog" onClick={(e) => { e.stopPropagation(); }}></AnchorButton>
              {( (self.props?.showTagManager || true) == true ? <Button icon="user" intent="warning" onClick={(e) => { self.openTagManager(self.props.service); e.stopPropagation(); }}/> : <span/> )}
            </ButtonGroup>
          </Callout>
        );
    }
    return ( <Callout style={buttonStyle} className={"cores-service-manage-button"} onClick={self.clickHandler}>
      <span style={{ fontWeight: "bold" }}> {(self.props?.showGroup === true ? `${self.props.service.group.name} - ` : "")} {name} {location}</span>
      <ButtonGroup>
        <Button icon="info-sign" intent="primary" onClick={(e) => { SiteDialogData.open(`${name} Information`, <div dangerouslySetInnerHTML={{ __html: serviceNotes }}/>); e.stopPropagation(); }}/>
      </ButtonGroup>
    </Callout> );


  }
}

export default ServiceButton;
