import React from "react"
import { Classes } from "@blueprintjs/core";

const GroupButton = ({group, color}) => {
  const buttonStyle = { 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color,
    color: "white",
    fontWeight: "bold",
    fontSize: "0.7rem",
    cursor : "default", 
    width: "250px", 
    border: '1px solid #232323'
  }
  return (<a className={Classes.CALLOUT} style={buttonStyle} href={"#/group/"+ group.id}>
    {group.name}
  </a> );
}

export default GroupButton;
