import { debounce } from "lodash"
import React from 'react';
import GroupData from "../Data/GroupData";
import AppData from "data-modules/AppData";
import ServiceData from "../Data/ServiceData";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();

import UnitList from "./UnitList"
import ServiceSearch from "./ServiceSearch"

class HomeModule extends React.Component {

  constructor () {

    super()

    this.state = { 
      groups : [],
      service_search_value: "",
      service_search_results: [],
      isLoadingResults: false
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.onGroupsLoaded = this.onGroupsLoaded.bind(this)
    this.onServicesLoaded = this.onServicesLoaded.bind(this);
    this.onLoadError = this.onLoadError.bind(this)
    this.onAjaxError = this.onAjaxError.bind(this)
    this.getUnitColor = this.getUnitColor.bind(this)
    this.updateSearchValue = this.updateSearchValue.bind(this);
  }

  componentDidMount () {

    DM.add(GroupData.registerListener("LIST_LOADED", this.onGroupsLoaded), GroupData);
    DM.add(GroupData.registerListener("LIST_LOAD_ERROR", this.onLoadError), GroupData);
    DM.add(ServiceData.registerListener("LIST_LOADED", this.onServicesLoaded), ServiceData);
    DM.add(ServiceData.registerListener("LIST_LOAD_ERROR", this.onLoadError), ServiceData);
    GroupData.getGroups();
  }

  componentWillUnmount () {
    DM.clear();
  }

  onGroupsLoaded ({records, count}, action) {
    this.setState({ groups : records });
  }

  onServicesLoaded(payload, action) {
    this.setState({ service_search_results: payload, isLoadingResults : false});
  }

  debouncedSearch = debounce((value) => {
    ServiceData.searchAvailable(value);
  }, 250)

  updateSearchValue(event) {
    let self = this;
    if(event.target.value.length < 3) { 
      self.setState({ service_search_value: event.target.value, service_search_results: [] })  
    } else {
      self.setState({ service_search_value: event.target.value, isLoadingResults: true });
      this.debouncedSearch(event.target.value);
    }
  }

  onLoadError (payload, action) {
    this.setState({ groups : [] });
  }

  onAjaxError (payload, action) {
    this.setState({ groups : [] });
  }

  getUnitColor (unit_id) {
    switch(unit_id) {
      case 0 : {return "#ad4737";} break;
      case 1 : {return "#447886";} break;
      case 2 : {return "#26823e";} break;
      default : { return "gray"; }
    }
  }

  getUnits () {
    var self = this;
    var units = this.state.groups.reduce(function (acc, group) {
      var parent_unit = group.parent_unit;
      let unit_id = parent_unit.id;
      if(typeof acc[unit_id] == "undefined") {
        var unit = {};
        unit.name = group.parent_unit.label;
        unit.id = unit_id;
        unit.color = ((puc) => {
          if(puc.length > 0) { return puc; }
          return "gray";
        })(parent_unit.label_color);
        unit.groups = [];
        acc[unit_id] = unit;
      }
      let existing_unit = acc[unit_id];
      existing_unit.groups.push(group);
      acc[unit_id] = existing_unit;
      return acc;
    }, {});
    return units;
  }

  render () {
      const self = this;
      const units = this.getUnits();
      return (
        <div>
          <ServiceSearch 
            searchValue={self.state.service_search_value} 
            searchResults={self.state.service_search_results} 
            updateSearchValue={self.updateSearchValue}
            getIsManager={AppData.authorize.bind(null, 20)}
            isLoading={self.state.isLoadingResults}
          />
          <UnitList units={units} getIsManager={AppData.authorize.bind(null, 20)}/>
        </div>
      );
  }
}

export default HomeModule;
