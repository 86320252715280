import React from "react";
import NewsData from "data-modules/NewsData";
import { Callout, Button, ControlGroup, InputGroup, AnchorButton, H5, Popover, Classes } from "@blueprintjs/core"
import moment from "moment";
import PagedActionList from "./PagedActionList"
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

class ManageNewsItems extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isCreating: false,
      pendingContent: "",
      pendingTitle: "",
      responseMessage: null, 
      isEditingHidden: false,
      isLoadingHidden: true,
      hiddenNewsList: [],
      currentPage: 1,
      pageSize: 10,
      totalHiddenCount: 0
    };
    this.startEditing = this.startEditing.bind(this);
    this.endEditing = this.endEditing.bind(this);
    this.startHiddenEditing = this.startHiddenEditing.bind(this);
    this.getHiddenRecords = this.getHiddenRecords.bind(this);
  }

  startEditing () { this.setState({ isCreating: true }); }
  startHiddenEditing () { 
    if(this.state.isEditingHidden) { this.setState({ isEditingHidden: false }); return; }
    
    this.getHiddenRecords()
    this.setState({ isEditingHidden: true }); 

  }
  getHiddenRecords() {
    NewsData.getRecordList({ _page: this.state.currentPage, _pageSize: this.state.pageSize, where: { visible: 0, group_id: this.props.groupId } }).then(results => {
      this.setState({ isLoadingHidden: false, hiddenNewsList: results.records, totalHiddenCount: results.count }); 
    })
  }
  endEditing () { 
    if(confirm("Are you sure you want to create this news item?")) {
      let createData = {
        timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
        title: this.state.pendingTitle,
        visible: 1,
        content: this.state.pendingContent,
        group_id: this.props.groupId
      };
      NewsData.createRecord(createData).then((response) => {
        this.setState({ isCreating: false });
        if(typeof this.props.onEndEdit == "function") { this.props.onEndEdit({ newTitle: this.state.pendingTitle, newContent: this.state.pendingContent }); }
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  render() {
    let self = this;
    if(this.props.show !== true) { return <div></div> }

    if(!this.state.isCreating) {

      let hiddenNewsList = <div> Loading List... </div>
      if(!this.state.isLoadingHidden) {
        hiddenNewsList = <PagedActionList 
          records={this.state.hiddenNewsList}
          currentPage={this.state.currentPage}
          pageSize={this.state.pageSize}
          totalRecordCount={this.state.totalHiddenCount}
          onPageAdvance={(newPage) => { this.setState({ currentPage: newPage }, () => { self.getHiddenRecords() }) }}
          onPageRetreat={(newPage) => { this.setState({ currentPage: newPage }, () => { self.getHiddenRecords() }) }}
          labelExtractor={(record) => { return `${record.title} (${moment(record.timestamp).format("YYYY-MM-DD HH:mm")})`; }}
          actions={[
            { icon: "eye-open", onClick: (record) => { 
                if(confirm("Are you sure you want to un-hide this news item?")) {
                  record.visible = 1;
                  NewsData.updateRecord(record).then(() => { 
                    self.getHiddenRecords();
                    if(typeof self.props.onEndEdit == "function") { self.props.onEndEdit(); }
                  });
                }
              }
            }
          ]}
        />
      }

      return (<ControlGroup style={{ marginBottom: 5 }}> 
        <Button icon="add" intent="success" onClick={() => {this.setState({ isCreating: true, pendingTitle: "News Title", pendingContent: "Content Here" });}}> Add News Item </Button>
        <Popover
                interactionKind="click"
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                placement="bottom" 
                content={(hiddenNewsList)} isOpen={this.state.isEditingHidden} 
                onInteraction={(nextOpenState) => { this.setState({ isEditingHidden: nextOpenState }); }}
                renderTarget={({ isOpen, ref, ...targetProps }) =>(<Button {...targetProps} elementRef={ref} icon="eye-open" intent="warning" onClick={this.startHiddenEditing}> Manage Hidden </Button>)}/>
        <AnchorButton intent="primary" href={`#/manage/group/${this.props.groupId}/news`} target="_blank" rightIcon="share"> Manage Group News </AnchorButton>
      </ControlGroup>)
    }

    return ( <Callout style={{ backgroundColor: "#cecece", marginBottom: 5 }}>
      <H5> Create News Item </H5>
      <Callout style={{ backgroundColor: "#efefef" }}>
        <H5> Title </H5>
        <InputGroup type="text" fill={true} style={{ marginBottom: 5}} onChange={(evt) => { this.setState({ pendingTitle: evt.target.value })}} value={this.state.pendingTitle}/>
        <H5> Content </H5>
        <MDEditor onChange={(value) => { this.setState({ pendingContent: value })}} value={this.state.pendingContent} previewOptions={{ rehypePlugins: [[rehypeSanitize]], }}/>
      </Callout>
      <div style={{ marginTop: 5, display: "flex", justifyContent: "right" }} > 
        <ControlGroup>
          <Button icon="tick" onClick={this.endEditing} intent="success" text="Create"/>
          <Button icon="cross" onClick={() => { this.setState({ isCreating: false }); }} intent="danger" text="Cancel"/>
        </ControlGroup> 
      </div>
    </Callout> );
  }
}

export default ManageNewsItems;
